<template>
  <div>
    <!-- <PageTopBanner :data="bannerInfo" /> -->
    <Faqs />
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import Faqs from './components/Faqs.vue';

export default {
  name: 'FAQ',
  components: { 
    // PageTopBanner,
    Faqs,
  },
  data() {
    return {
      bannerInfo: {
        title: 'FAQ'
      },
    };
  },
};
</script>
<style>
</style>
